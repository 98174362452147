let mutation = {
    doneName(state, val) {
        state.name+=val
    },
    doneAge(state) {
        state.age++
    },
    doneing(state, val) {
        state.done+=val
    }
}

export default mutation