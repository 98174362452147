import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'ant-design-vue/dist/antd.css'

import 'element-plus/dist/index.css'

import locale from "element-plus/lib/locale/lang/zh-cn"//需要新加的代码
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import en from "element-plus/lib/locale/lang/en"

import * as Icons from "@element-plus/icons-vue"
import i18n from './components/language/index.js'

import App from './App.vue'
import router from './router'
import store from './store'
import ScrollShow from './util/ScrollShow'
import { Input } from 'ant-design-vue'

import "./assets/iconfont/iconfont.css"

import '@/util/rem.js'
import 'reset-css'
import animated from "animate.css"
// if (process.env.NODE_ENV === 'development') {
//     const { mockXHR } = require('../mock')
//     mockXHR()
// }
import VueLazyload from "vue-lazyload"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import '@/assets/common.scss'
import '@/assets/css/product.scss'
import '@/assets/css/case.scss'
import '@/assets/css/service.scss'
import '@/assets/css/news.scss'
import '@/assets/css/about.scss'

//或者自定义配置插件
// Vue.use(VueLazyload, {
//     preLoad: 1.3,
//     error: require('../src/assets/image/error.png'),
//     loading: require('../src/assets/image/loading.gif'),
//     attempt: 1
// })
// appa.use(ElementPlus, { locale: localStorage.getItem('lang') == 'en' ? zhCn : en, })


let appa = createApp(App)
appa.use(store)
appa.use(router)
appa.use(ScrollShow)
appa.use(i18n)
appa.use(Input)
appa.use(animated)
appa.use(VueLazyload, {
    preLoad: 1.3,
    // error: require('../src/assets/image/error.png'),
    // loading: require('/src/assets/image/load.png'),
    attempt: 1
})
appa.use(ElementPlus, { locale })
appa.mount('#app')


Object.keys(Icons).forEach((key) => {
    // console.log(key)
    appa.component(key, Icons[key])//JS中用这行
    // app.component(key, Icons[key as keyof typeof Icons]);//TS中用这行
})
