import { createStore } from 'vuex'
import mutations from './mutation'
import state from './state'
import actions from './action'

export default createStore({
  // namespaced: true,
  state,
  getters: {
  },
  mutations,
  actions,
  modules: {
  }
})
