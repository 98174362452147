
const routes = [
    {
        path: '/',
        component: () => import('@/views/HomeView.vue'),
        meta: { title: '首页' },
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/website/index.vue'),
                meta: { title: '首页' }
            },
            {
                path: '/aboutUs',
                name: 'aboutUs',
                component: () => import('@/views/website/AboutUs.vue'),
                meta: { title: '关于我们' }
            },
            {
                path: '/smartMechanicalMethod',
                name: 'smartMechanicalMethod',
                component: () => import('@/views/website/SmartMechanicalMethod.vue'),
                meta: { title: '智能消杀防疫解决方案' }
            },
            {
                path: '/energyMethod',
                name: 'energyMethod',
                component: () => import('@/views/website/EnergyMethod.vue'),
                meta: { title: '绿色能耗解决方案' }
            },
            {
                path: '/smartControlMethod',
                name: 'smartControlMethod',
                component: () => import('@/views/website/SmartControlMethod.vue'),
                meta: { title: '智能控制解决方案' }
            },
            {
                path: '/envirMethod',
                name: 'envirMethod',
                component: () => import('@/views/website/EnvirMethod.vue'),
                meta: { title: '光环境解决方案' }
            },
            {
                path: '/airMethod',
                name: 'airMethod',
                component: () => import('@/views/website/AirMethod.vue'),
                meta: { title: '空气环境解决方案' }
            },
            {
                path: '/solutionCase',
                name: 'solutionCase',
                component: () => import('@/views/website/SolutionCase.vue'),
                meta: { title: '解决方案' }
            },
            {
                path: '/productCenter',
                name: 'productCenter',
                component: () => import('@/views/website/product/ProductCenter.vue'),
                meta: { title: '硬件产品' }
            },
            {
                path: '/productSoft',
                name: 'productSoft',
                component: () => import('@/views/website/product/ProductSoft.vue'),
                meta: { title: '软件产品' }
            },
            {
                path: '/classLightA1',
                name: 'classLightA1',
                component: () => import('@/views/website/product/ClassLightA1.vue'),
                meta: { title: '微晶护眼教室灯A1' }
            },
            {
                path: '/classLightA2',
                name: 'classLightA2',
                component: () => import('@/views/website/product/ClassLightA2.vue'),
                meta: { title: '格栅护眼教室灯A2' }
            },
            {
                path: '/classLightA3',
                name: 'classLightA3',
                component: () => import('@/views/website/product/ClassLightA3.vue'),
                meta: { title: '微晶护眼教室灯A3' }
            },
            {
                path: '/classLightB1',
                name: 'classLightB1',
                component: () => import('@/views/website/product/ClassLightB1.vue'),
                meta: { title: '微晶护眼教室灯B1' }
            },
            {
                path: '/classLightB2',
                name: 'classLightB2',
                component: () => import('@/views/website/product/ClassLightB2.vue'),
                meta: { title: '微晶护眼教室灯B2' }
            },
            {
                path: '/blackboardLightC1',
                name: 'blackboardLightC1',
                component: () => import('@/views/website/product/BlackboardLightC1.vue'),
                meta: { title: '定向投射板书灯C1' }
            },
            {
                path: '/blackboardLightC2',
                name: 'blackboardLightC2',
                component: () => import('@/views/website/product/BlackboardLightC2.vue'),
                meta: { title: '格栅防眩板书灯C2' }
            },
            {
                path: '/protectionLampClover',
                name: 'protectionLampClover',
                component: () => import('@/views/website/product/ProtectionLampClover.vue'),
                meta: { title: '童光护眼灯-三叶草款' }
            },
            {
                path: '/protectionLampSunlight',
                name: 'protectionLampSunlight',
                component: () => import('@/views/website/product/ProtectionLampSunlight.vue'),
                meta: { title: '童光护眼灯-三叶草款' }
            },
            {
                path: '/envirMonitor',
                name: 'envirMonitor',
                component: () => import('@/views/website/product/EnvirMonitor.vue'),
                meta: { title: '环境监测仪' }
            },
            {
                path: '/iotGateway',
                name: 'iotGateway',
                component: () => import('@/views/website/product/IotGateway.vue'),
                meta: { title: '物联网关' }
            },
            {
                path: '/smartPanel',
                name: 'smartPanel',
                component: () => import('@/views/website/product/SmartPanel.vue'),
                meta: { title: '智能灯控面板' }
            },
            {
                path: '/uvControl',
                name: 'uvControl',
                component: () => import('@/views/website/product/UVControl.vue'),
                meta: { title: '智能紫外线灯控制器' }
            },
            {
                path: '/smartSocket',
                name: 'smartSocket',
                component: () => import('@/views/website/product/SmartSocket.vue'),
                meta: { title: '智能插座' }
            },
            {
                path: '/integratedMachine',
                name: 'integratedMachine',
                component: () => import('@/views/website/product/IntegratedMachine.vue'),
                meta: { title: '智能插座' }
            },
            {
                path: '/intelligentSwitch',
                name: 'intelligentSwitch',
                component: () => import('@/views/website/product/IntelligentSwitch.vue'),
                meta: { title: '智能空开' }
            },
            {
                path: '/curtainControlSystem',
                name: 'curtainControlSystem',
                component: () => import('@/views/website/product/CurtainControlSystem.vue'),
                meta: { title: '窗帘智控系统' }
            },
            {
                path: '/touchPanel',
                name: 'touchPanel',
                component: () => import('@/views/website/product/TouchPanel.vue'),
                meta: { title: '智能触控面板' }
            },
            {
                path: '/singleEtrlControl',
                name: 'singleEtrlControl',
                component: () => import('@/views/website/product/SingleEtrlControl.vue'),
                meta: { title: '单路控制器' }
            },
            {
                path: '/negativeDisinfection',
                name: 'negativeDisinfection',
                component: () => import('@/views/website/product/NegativeDisinfection.vue'),
                meta: { title: '生态负离子净化消毒机' }
            },
            {
                path: '/intelligentRobot',
                name: 'intelligentRobot',
                component: () => import('@/views/website/product/IntelligentRobot.vue'),
                meta: { title: '智能消杀机器人' }
            },
            {
                path: '/controlPanel',
                name: 'controlPanel',
                component: () => import('@/views/website/product/ControlPanel.vue'),
                meta: { title: '智控面板' }
            },
            {
                path: '/serviceCenter',
                name: 'serviceCenter',
                component: () => import('@/views/website/service/ServiceCenter.vue'),
                meta: { title: '服务中心' }
            },
            {
                path: '/afterSaleService',
                name: 'afterSaleService',
                component: () => import('@/views/website/service/AfterSaleService.vue'),
                meta: { title: '售后服务' }
            },
            {
                path: '/audioProcessor',
                name: 'audioProcessor',
                component: () => import('@/views/website/product/AudioProcessor.vue'),
                meta: { title: 'AI音频处理器' }
            },
            {
                path: '/wirelessMicrophone',
                name: 'wirelessMicrophone',
                component: () => import('@/views/website/product/WirelessMicrophone.vue'),
                meta: { title: '无线领夹麦' }
            },
            {
                path: '/hangingWheat',
                name: 'hangingWheat',
                component: () => import('@/views/website/product/HangingWheat.vue'),
                meta: { title: '心形指向性吊麦' }
            },
            {
                path: '/highFidelityMicrophone',
                name: 'highFidelityMicrophone',
                component: () => import('@/views/website/product/HighFidelityMicrophone.vue'),
                meta: { title: '高保真多功能麦' }
            },
            {
                path: '/magneticLock',
                name: 'magneticLock',
                component: () => import('@/views/website/product/MagneticLock.vue'),
                meta: { title: '磁力锁控制器' }
            },
            {
                path: '/integratedController',
                name: 'integratedController',
                component: () => import('@/views/website/product/IntegratedController.vue'),
                meta: { title: '一体机控制器' }
            },
            {
                path: '/projectorController',
                name: 'projectorController',
                component: () => import('@/views/website/product/ProjectorController.vue'),
                meta: { title: '投影仪控制器' }
            },
            {
                path: '/multifunctionalSound',
                name: 'multifunctionalSound',
                component: () => import('@/views/website/product/MultifunctionalSound.vue'),
                meta: { title: '多功能扩声主机' }
            },
            {
                path: '/smartPanelChild',
                name: 'smartPanelChild',
                component: () => import('@/views/website/product/SmartPanelChild.vue'),
                meta: { title: '智能灯控面板-幼儿园定制版' }
            },
            {
                path: '/newsDetail1',
                name: 'newsDetail1',
                component: () => import('@/views/website/news/NewsDetail1.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail2',
                name: 'newsDetail2',
                component: () => import('@/views/website/news/NewsDetail2.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail3',
                name: 'newsDetail3',
                component: () => import('@/views/website/news/NewsDetail3.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail4',
                name: 'newsDetail4',
                component: () => import('@/views/website/news/NewsDetail4.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/website/news/News.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail5',
                name: 'newsDetail5',
                component: () => import('@/views/website/news/NewsDetail5.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail6',
                name: 'newsDetail6',
                component: () => import('@/views/website/news/NewsDetail6.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail7',
                name: 'newsDetail7',
                component: () => import('@/views/website/news/NewsDetail7.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail8',
                name: 'newsDetail8',
                component: () => import('@/views/website/news/NewsDetail8.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail9',
                name: 'newsDetail9',
                component: () => import('@/views/website/news/NewsDetail9.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail10',
                name: 'newsDetail10',
                component: () => import('@/views/website/news/NewsDetail10.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail11',
                name: 'newsDetail11',
                component: () => import('@/views/website/news/NewsDetail11.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail12',
                name: 'newsDetail12',
                component: () => import('@/views/website/news/NewsDetail12.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/newsDetail13',
                name: 'newsDetail13',
                component: () => import('@/views/website/news/NewsDetail13.vue'),
                meta: { title: '新闻中心' }
            },
            {
                path: '/kindergartenPlatform',
                name: 'kindergartenPlatform',
                component: () => import('@/views/website/product/soft/KindergartenPlatform.vue'),
                meta: { title: '智慧幼儿园管理云平台 ' }
            },
            {
                path: '/robotPlatform',
                name: 'robotPlatform',
                component: () => import('@/views/website/product/soft/RobotPlatform.vue'),
                meta: { title: 'AI智能消杀防疫管理云平台 ' }
            },
            {
                path: '/smartClassPlatform',
                name: 'smartClassPlatform',
                component: () => import('@/views/website/product/soft/SmartClassPlatform.vue'),
                meta: { title: 'K12智慧健康教室管理云平台 ' }
            },
            {
                path: '/airController',
                name: 'airController',
                component: () => import('@/views/website/product/AirController.vue'),
                meta: { title: '智能空调控制器 ' }
            },
            {
                path: '/searchPage',
                name: 'searchPage',
                component: () => import('@/views/website/SearchPage.vue'),
                meta: { title: '产品搜索 ' }
            },
            {
                path: '/honoraryQuality',
                name: 'honoraryQuality',
                component: () => import('@/views/website/HonoraryQuality.vue'),
                meta: { title: '荣誉资质 ' }
            }
        ]
    }
]

export default routes