// 计算根节点1rem的值
(function (doc, win) {
    var docEl = doc.documentElement,
        // recalF,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            console.log('000')
            var clientWidth = docEl.clientWidth
            if (!clientWidth) return
            // 按照效果图尺寸及方便计算的规则，确认1rem基本字号大小。此处为1rem=20px
            if (clientWidth <= 768) {
                docEl.style.fontSize = clientWidth / 7.50 + 'px'
            } else if (clientWidth > 768 && clientWidth <= 1024) {
                docEl.style.fontSize = clientWidth / 7.50 + 'px'

            } else {
                // docEl.style.fontSize = parseInt((clientWidth / 19.2)) + 'px'

            }

        }
    if (!doc.addEventListener) return
    win.addEventListener(resizeEvt, recalc, false)
    doc.addEventListener('DOMContentLoaded', recalc, false)

})(document, window)
