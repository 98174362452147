export default (app) => {
    app.directive('scrollShow', {
        mounted (el, binding) {
            document.getElementById('app').addEventListener('scroll', () => {
                let s = (window.innerHeight || document.documentElement.clientHeight) - (el.getBoundingClientRect().top || el.offsetTop) > 30
                if (s) {
                    el.style.transform = 'translate3d(0,0,0)'
                    el.style.opacity = '1'
                }
            })
        },
        unmounted () {
            // location.reload()
            // document.documentElement.scrollTop = 0
            // window.removeEventListener
        }
    })
}